import React, { useContext, useEffect, useState } from 'react';
import Mappedarray from '../Body/Mappedarray/Mappedarray';
import Logo from '../reuseable/Logo/Logo';
import NavBarLink from './NavBarLink';
import './Navbar.css';
import { ContextApp } from '../../ContextAPI';
import AppButton from '../reuseable/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function Navbar(props) {
  const { links } = props;
  const { scrolled, setScrolled } = useContext(ContextApp);
  const [navmenu, setNavmenu] = useState(false);

  const linksrow = (
    <Mappedarray array={links}>
      {({ prop }) => (
        <NavBarLink link={prop} />
      )}
    </Mappedarray>
  );

  function handleScroll() {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolled]);

  return (
    <div className={`navbar ${scrolled ? 'navscrolled' : ''}`}>
      <Logo text='Bearclaw' text2=' Technologies Ltd.' />
      <div className="links">{linksrow}</div>
      <div className={`mobbtn ${navmenu ? 'open' : 'closed'}`} onClick={() => setNavmenu(!navmenu)}>
        <hr className="l1" />
        <hr className="l2" />
        <hr className="l3" />
      </div>

      <div className={navmenu ? 'navmenu navmenu-enter' : 'navmenu navmenu-exit'}>
        <Logo text='Bearclaw' text2=' Technologies Ltd.' />
        <div className="linksmenu">
          {links?.map((link) => {
            return <NavBarLink link={link} clickEvent={() => setNavmenu(false)} />;
          })}
          <AppButton 
            text='Learn More' 
            icon='fal fa-arrow-right'
            clickEvent='./../Body/Aboutsection/Aboutsection'
          />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
