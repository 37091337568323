export function addNotification(parameters, time=7, position='tr'){
  const options = parameters;

  // Simply log the notification for now
  console.log({
    message: options.msg,
    level: 'warning',
    position:  position,
    autoDismiss: time
  });
}
