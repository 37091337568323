import React from 'react';
import Bannerprops from '../Banner/Bannerprops';
import GrayBackground from '../../reuseable/Icon/grayBackground.jpg';
import './Aboutsection.css';
import { sections } from './About.js';

function Aboutsection() {
  return (
    <div className="Aboutsection">
      <Bannerprops 
        img={GrayBackground}
        title='About Us' 
        text='Explore the diverse services offered by Bearclaw Technologies Ltd to help establish your online presence!'
      />
      <div className="aboutcont">
        {sections.map((section, index) => (
          <div className="aboutme-section" key={index}>
            <h2 className="aboutme-section-title">{section.title}</h2>
            <p className="aboutme-section-text">{section.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Aboutsection;
