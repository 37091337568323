import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer" id='footer'>
      <div className="bottomcontent">
        <div className="rightsreserved">
          <small className='graytext'>
            All Rights Reserved Bearclaw Technologies Ltd.
          </small>
        </div>
      </div>
    </div>
  );
}

export default Footer;
