import WebDevelopmentIcon from './reuseable/Icon/webDev.jpg';
import MobileAppDevelopmentIcon from './reuseable/Icon/mobileDev.jpg';
import TechnologyConsultingIcon from './reuseable/Icon/innovationDev.jpg';
import ComputerBuildingMaintenanceIcon from './reuseable/Icon/computerDev.jpg';
import FinancialBotCodingIcon from './reuseable/Icon/tradeDev.jpg';
import MoreServicesIcon from './reuseable/Icon/contactDev.jpg';
import CallUsIcon from './reuseable/Icon/callDev.jpg';
import SendMailIcon from './reuseable/Icon/emailDev.jpg';
import LinkedInIcon from './reuseable/Icon/linkedinDev.jpg';
import AboutUsImage from './reuseable/Icon/introductionDev.jpg';
import OurSkillsImage from './reuseable/Icon/juggleDev.jpg';
import OurServicesImage from './reuseable/Icon/servicesDev.jpg';
import TwitterLinkIcon from './reuseable/Icon/twitterCircle.jpg';
import LinkedInLinkIcon from './reuseable/Icon/linkedInCircle.jpg';



export const links = [
  {
    link: '/',
    text: 'Home',
    exact: true,
  
  },
  {
    link: '/aboutsection',
    text: 'About',
  }
  ,{
    link: '/services',
    text: 'Services',
    dropdown: [
      {
        link: '/services#websites',
        text: 'Websites',
        icon: WebDevelopmentIcon
      },
      {
        link: '/services#webapps',
        text: 'Web Apps',
        icon: WebDevelopmentIcon
      },
      {
        link: '/services#mobileapps',
        text: 'Mobile Apps',
        icon: MobileAppDevelopmentIcon
      },
    ]
  },
  {
    link: '/contact',
    text: 'Contact',
  }
]

export const socialicons = [
  {text: 'Twitter', icon: TwitterLinkIcon, link: ''},
  {text: 'Linked In', icon: LinkedInLinkIcon, link: ''},
]

export const profile = [
  {
    "title": "About Us",
    "text": "Bearclaw Technologies Ltd. is a software development and technology solutions company. Our team is extremely motivated to constantly develop skills and grow professionally. We are confident in our ability to deliver high-quality software and services, always coming up with innovative ideas to meet our clients' business goals.",
    "icon": AboutUsImage,
  },
  {
    "title": "Our Skills",
    "text": "We specialize in front-end and back-end development, responsive design, user experience (UX), database management, API integration, testing and debugging, performance optimization, computer building & maintenance, and developing low latency financial algorithms.",
    "icon": OurSkillsImage,
  },
  {
    "title": "Our Services",
    "text": "Our services range from web development, mobile app development, technology consulting, computer building & maintenance, and financial bot coding. We are dedicated to empowering businesses through cutting-edge technology, tailored to their needs.",
    "icon": OurServicesImage,
  }
]
export const bars = [
  {
    skill: 'Web Development',
    percent: 90,
    icon: 'fab fa-html5',
    text: 'Our team excels at developing modern and effective web applications!'
  },
  {
    skill: 'Mobile App Development',
    percent: 85,
    icon: 'fab fa-react',
    text: 'We transform your vision into engaging mobile applications!'
  },
  {
    skill: 'UX/UI Design',
    percent: 90,
    icon: 'fad fa-image',
    text: 'Designing seamless user experiences is our forte!'
  },
  {
    skill: 'JavaScript',
    percent: 95,
    icon: 'fab fa-js-square',
    text: 'Expertise in JavaScript enables us to create dynamic web applications!'
  },
  {
    skill: 'Database Management',
    percent: 85,
    icon: 'fad fa-database',
    text: 'Proficient in database management to keep your data secure and accessible!'
  },
  {
    skill: 'API Integration',
    percent: 80,
    icon: 'fab fa-node-js',
    text: 'Skilled in integrating APIs for seamless data exchange!'
  },
  {
    skill:  'Performance Optimization',
    percent: 85,
    icon: 'fab fa-optin-monster',
    text: 'We ensure your applications run smoothly with optimal performance!'
  }, 
]

export const services = [
  {
    text: 'Building and maintaining modern, powerful web applications.',
    title: 'Web Development',
    icon: WebDevelopmentIcon,
    link: '/services'
  },
  {
    text: 'Creating engaging mobile applications for all major platforms.',
    title: 'Mobile App Development',
    icon: MobileAppDevelopmentIcon,
    link: '/services'
  },
  {
    text: 'Driving innovation with expert technological insights.',
    title: 'Technology Consulting',
    icon: TechnologyConsultingIcon,
    link: '/services'
  },
  {
    text: 'Designing and maintaining custom computer systems.',
    title: 'Computer Building & Maintenance',
    icon: ComputerBuildingMaintenanceIcon,
    link: '/services'
  },
  {
    text: 'Developing automated bots for financial applications using C++.',
    title: 'Financial Bot Coding',
    icon: FinancialBotCodingIcon,
    link: '/services'
  },
  {
    text: 'Contact us for more information on our comprehensive technology solutions.',
    title: 'More Services',
    icon: MoreServicesIcon,
    link: '/services'
  }
]

export const contactboxes = [
  {
    title: 'Call Us',
    text: '(970) 214-3227',
    link: 'tel:9702143227',
    icon: CallUsIcon,
    notblank: true,
  },
  {
    title: 'Email Us',
    text: 'services@bearclawtech.org',
    link: 'mailto:solomon@bearclawtech.org',
    icon: SendMailIcon,
  },
  {
    title: 'LinkedIn',
    text: 'Bearclaw Technologies Ltd.',
    link: 'https://www.linkedin.com/sxbear',
    icon: LinkedInIcon,
  }
]
export const style = {
  NotificationItem: {
    DefaultStyle: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      border: 'solid 1px rgb(0, 0,0,0)',
      boxShadow: 'var(--light-shadow)',
      height: '45px',
      display: 'flex',
      alignItems: 'center'
    },
    warning: {
    }
  }
}

export const contactinputs = [
  {
    text: 'Full Name',
    value: 'name',
    name: 'from_name'
  },
  {
    text: 'Email',
    value: 'email',
    name: 'from_email'
  },
  {
    text: 'Message',
    value: 'msg',
    textarea: true,
    name: 'message'
  }
]
export  const linksfooter=[
  {
    link: '/services',
    text: 'Web Design',
    exact: true,
  },
  {
    link: '/website/saved',
    text: 'Creativity',
 },
  {
    link: '/website/salaries',
    text: 'Find Salaries',
  },
  {
    link: '/dashboard/applications/applications',
    text: 'Applied To',
    
  }
]
export const gridservices = [
  {
    title: 'Web Development',
    texts: ['Building and maintaining modern web applications.'],
    img: WebDevelopmentIcon,
    reverse: false,
    text: 'We build and maintain cutting-edge web applications tailored to your business needs.',
    link: '/web-development'
  },
  {
    title: 'Mobile App Development',
    texts: ['Creating engaging mobile applications for all major platforms.'],
    img: MobileAppDevelopmentIcon,
    reverse: true,
    text: 'We design and develop intuitive mobile applications for iOS and Android platforms.',
    link: '/mobile-app-development'
  },
  {
    title: 'Technology Consulting',
    texts: ['Providing expert insights to drive technological innovation.'],
    img: TechnologyConsultingIcon,
    reverse: false,
    text: 'Our tech consultants are here to provide expert guidance and innovative solutions.',
    link: '/technology-consulting'
  },
  {
    title: 'Computer Building & Maintenance',
    texts: ['Designing and maintaining custom computer systems.'],
    img: ComputerBuildingMaintenanceIcon,
    reverse: true,
    text: 'From custom-built systems to routine maintenance, we’ve got your computing needs covered.',
    link: '/computer-building-maintenance'
  },
  {
    title: 'Financial Bot Coding',
    texts: ['Developing automated bots for financial applications using C++.'],
    img: FinancialBotCodingIcon,
    reverse: false,
    text: 'Leverage our expertise in creating sophisticated financial bots for various applications.',
    link: '/financial-bot-coding'
  }
];
