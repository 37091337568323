import React from 'react';
import Iconbox from '../../reuseable/Icon/Iconbox';
import Textarrow from '../../reuseable/Textarrow/Textarrow';
import './slide.css'

function Slide({ slide, currentslide, index }) {
  function determineFade(type) {
    if (currentslide === index) return '';
    return `fade-${type}`;
  }

  return (
    <div className={`slide ${currentslide === index ? 'active' : 'inactive'}`}>
      <div className="slide-content-center">
        <div className="title-container" data-aos={determineFade('right')}>
          <h3>
            <Iconbox icon={slide.icon} />
            <span>{slide.title}</span>
          </h3>
        </div>
        <p className='graytext' data-aos={determineFade('right')}>
          <i className={'fad fa-' + slide.texticon}></i>
          <span>{slide.text}</span>
        </p>
        <div className="btns" data-aos={determineFade('up')}>
          <Textarrow link='/aboutsection' />
        </div>
      </div>
    </div>
  );
}

export default Slide;
