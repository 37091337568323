import './Aboutsection.css';

export const sections = [
  {
    title: 'Who We Are',
    text: `Bearclaw Technologies Ltd. was founded in June 2023 as a vision for the future of software development and technology solutions. Utilizing a unique blend of knowledge and experience, Bearclaw Technologies is adept at crafting efficient, robust, and tailor-made software solutions. The underlying mission is to empower businesses to achieve their distinct objectives and make a mark in their respective markets through the power of technological innovation and adaptation.`
  },
  {
    title: 'Our Skills',
    text: `At Bearclaw Technologies Ltd., we understand that diverse skills are the backbone of successful software development. Specializing in a wide spectrum of areas, including but not limited to front-end and back-end development, responsive design, user experience (UX), database management, and API integration, Bearclaw Technologies effectively tackles even the most complex tech challenges. The vision to create comes to life by harnessing the expertise of these diverse domains.`
  },
  {
    title: 'Our Services',
    text: `Bearclaw Technologies Ltd. extends a comprehensive range of services to cover all aspects of web and mobile application development. This includes technology consulting for informed decision-making, computer building & maintenance for smooth technological operations, and development of automated bots for financial applications. The approach to service delivery is both innovative and tailored, making sure to cater to the versatile tech needs of the clients. The goal is to be a comprehensive solution for all tech needs, offering solutions that evolve with the business.`
  },
  {
    title: 'Our Journey',
    text: `The inception of Bearclaw Technologies Ltd. is a story of a vision coming to life. Our founder, Solomon Xavier Bear, a Computer Science and Economics graduate from CU Boulder, utilized his leadership experience from leading companies like YRC Freight and his unyielding passion for software development to establish Bearclaw Technologies. With a commitment to delivering custom solutions that meet unique business challenges, Bearclaw Technologies has evolved into a highly respected name in the realm of software development, serving businesses of all sizes. The journey, much like the services, is a continuous evolution guided by client needs and industry dynamics.`
  },
  {
    title: 'Our Approach',
    text: `Bearclaw Technologies operates with a single, steadfast commitment - surpassing client expectations. This principle permeates every aspect of our operation. Our approach's flexibility and adaptability allow us to navigate the complexities of software development, delivering timely, client-centric solutions. Coupled with a dedication to continuous learning and innovation, this flexibility enables us to stay abreast of advancements and deliver state-of-the-art solutions. Whether you're a startup venturing into the technological landscape or an established corporation seeking modernization, Bearclaw Technologies is poised to assist in shaping your business's future with bespoke software solutions.`
  },
];
