import React, { useState } from 'react';
import './Aboutme.css'; 
import { sections } from './About.js';

const AboutMe = () => {

  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection(prevExpanded => prevExpanded === index ? null : index);
  };

  return (
    <div className="aboutme-container"> 
      
      <div className="about-banner">
        <h1 className="about-title">About Us</h1>
        <p className="about-text">Learn more about Bearclaw Technologies Ltd. and our services.</p> 
      </div>
      {sections.map((section, index) => (
        <div
          key={index}
          className={`aboutme-section ${expandedSection === index ? "expanded" : ""}`}
          onClick={() => toggleSection(index)} // Move the onClick to the entire section div
        >
          <div className="aboutme-section-header">
            <h2 className="aboutme-section-title">{section.title}</h2>
            <div className={`toggle-icon ${expandedSection === index ? "expanded" : ""}`}>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
          {expandedSection === index && (
            <p className="aboutme-section-text">{section.text}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default AboutMe;
