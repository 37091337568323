import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './Textarrow.css';

function Textarrow(props) {

  const {
    text='Read More', 
    // Replace icon with FontAwesomeIcon
    icon=<FontAwesomeIcon icon={faArrowRight} />, 
    link='./aboutsection'
  } = props;

  return (
    <Link to={link + '#top'} className="readmore">
      <span>{text}</span>
      {icon} 
    </Link>
  );

}

export default Textarrow;