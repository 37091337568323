import React, { useContext } from 'react'
import { HashLink } from 'react-router-hash-link'
import { ContextApp } from '../../../ContextAPI'
import './Dropdown.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function Dropdown(props) {
  const {dropdown, link} = props
  const {setKeyword} = useContext(ContextApp)
  const optionsrow = dropdown?.map(option=>{
    return (
      <HashLink 
      to={link.keyword ? '/works' : option.link}
      onClick={() => {link.keyword && setKeyword(option.link)}}
    >
      <span>
        <span>{option.text}</span> 
      </span>
      
      <FontAwesomeIcon icon={faChevronRight} />

    </HashLink>
    )
  })

  return (
    <div className="dropdown">
        {optionsrow}
    </div>
  )
}
export default Dropdown