import React from 'react';
import Banner from './Banner';
import './Bannerprops.css'

function Bannerprops(props) {
  const { img, title, text } = props;
  return (
    <Banner
      className='bp'
      style={{
        backgroundImage: `url(${img})`,
      }}
      html={
        <>
          <div className="text-container">
            <div className="centered-content">
              <h1>{title}</h1>
              <small className="graytext">{text}</small>
            </div>
          </div>
        </>
      }
    />
  );
}

export default Bannerprops;
