import React from 'react';
import Carousel from 'react-material-ui-carousel';

import Rippleicon from '../Icon/Rippleicon'; 
import './Carousel.css';

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function Carouselcont(props) {

  const {slides, setCurrentslide} = props;

  return (
    <Carousel
      className='carousel'
      
      // Original NavButton
      NavButton={({onClick, next}) => {
        return (
          <div onClick={onClick}>
            <Rippleicon  
              size={34}
              icon={<FontAwesomeIcon icon={next ? faArrowRight : faArrowLeft} />} 
            />
          </div>
        )  
      }}

      IndicatorIcon={<div className='line'></div>}
      activeIndicatorIconButtonProps={{className: 'active'}}
      interval={5000}
      onChange={e => setCurrentslide(e)}
    >
    
      {slides}
    
    </Carousel>
  );

}

export default Carouselcont;