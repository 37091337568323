import React from 'react';
import { HashLink } from 'react-router-hash-link';

function Gridservice(props) {
  const { title, texts, img, reverse, text, link } = props.service;

  return (
    <div
      id={title.replaceAll(' ', '').toLowerCase()}
      className={`gridservice ${reverse ? 'rowreverse' : ''}`}
      style={{ display: 'flex', alignItems: 'center' }} // Added style for vertical alignment
    >
      <div className={`content ${reverse}`} style={{ flex: 1 }}>
        <HashLink to={link + '#top'}>
          <h2>{title}</h2>
        </HashLink>
        <div className="texts">
          <div>
            {texts?.map((text) => {
              return <span>{text + '.'}</span>;
            })}
          </div>
          <small className="graytext">{text}</small>
        </div>
      </div>
      <div
        className="img"
        style={{ flex: 1, display: 'flex', justifyContent: 'center', maxWidth: '100%' }}
      >
        {/* Set a responsive image */}
        <img src={img} alt="" style={{ width: '100%', height: 'auto', maxWidth: '400px' }} />
        {/* Set maxWidth to limit the image size */}
      </div>
    </div>
  );
}

export default Gridservice;
