import React, { useContext } from 'react'
import { ContextApp } from '../../ContextAPI'
import Navbar from '../Navbar/Navbar'
import { links, style, } from '../Appconstant'
import './Body.css'
import { Route, Routes } from 'react-router-dom'
import Home from './Home/Home'
import Contact from './Contact/Contact'
import NotificationSystem from 'react-notification-system';
import Footer from './Footer/Footer'
import YouAreLost from './404/YouAreLost'
import Services from './Services/Services'
import { AnimatePresence } from 'framer-motion'
import Aboutme from './Aboutme/Aboutme'
import Aboutsection from './Aboutsection/Aboutsection'


function Body(props) {
  const {notifisystem} = useContext(ContextApp)

  return (
    <div className="body">

      <NotificationSystem ref={notifisystem} style={style} />

      <Navbar links={links}/>
     
      <AnimatePresence>
        <Routes>
          <Route path='/aboutme' element={<Aboutme />} />
          <Route path='/aboutsection' element={<Aboutsection />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/' element={<Home />} />
          <Route path='*' element={<YouAreLost />} />
        </Routes>
      </AnimatePresence>
      <Footer />
  </div>
  );
}
export default Body
