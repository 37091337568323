import React, { useEffect, useState } from 'react';
import { profile } from '../../Appconstant';
import Carouselcont from '../../reuseable/Carousel/Carouselcont';
import Typeonload from '../../reuseable/Typeonload/Typeonload';
import Aboutme from '../Aboutme/Aboutme';
import Banner from '../Banner/Banner';
import Slide from '../Banner/Slide';
import Featuressection from '../Featuressection/Featuressection';
import GrayBackground from '../../reuseable/Icon/grayBackground.jpg';
import './Home.css';

function Home(props) {
  const [currentslide, setCurrentslide] = useState(0);
  const slidesrow = profile?.map((el, i) => {
    return (
      <Slide key={i} slide={el} index={i} currentslide={currentslide}>
        <h4>{el.title}</h4>
        <p>{el.text}</p>
      </Slide>
    );
  });

  const bannercont = {
    html: (
      <>
        <div className="title">
          <h2>
            <Typeonload text={'Bearclaw Technologies Ltd.'} />
            <small className="graytext" style={{ fontSize: '14px' }}>
              <Typeonload text="Software Development" startDelay={2000} />
            </small>
          </h2>
          <div className="hr"></div>
        </div>
        <Carouselcont
          slides={slidesrow}
          setCurrentslide={setCurrentslide}
          currentslide={currentslide}
        />
      </>
    ),
  };

  useEffect(() => {
    setCurrentslide(0);
  }, []);

  return (
    <div className="home">
      <Banner
        name="Solomon Bear"
        hr={true}
        html={bannercont.html}
        height="100vh"
        style={{
          backgroundImage: `url(${GrayBackground})`,
          backgroundAttachment: 'fixed',
        }}
      />
      <Aboutme />
      <Featuressection />
    </div>
  );
}

export default Home;
