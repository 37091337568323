import React from 'react'
import { Link } from 'react-router-dom'
import './Logo.css'
import logo from './logo.jpg'; // Import the logo

function Logo(props) {
  const {text='Bearclaw', text2='Technologies Ltd.'} = props

  return (
    <Link className="logo" to=''>
      <img src={logo} alt="Bearclaw Technologies Logo"/>
      <span>
        <strong>{text}</strong>
        <span>{text2}</span>
      </span>
    </Link>
  )
}
export default Logo
