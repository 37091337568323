import React, { useEffect } from 'react'
import "./css/Styles.css";
import ContextAppProvider from './ContextAPI';
import Body from './components/Body/Body';
import { BrowserRouter as Router } from 'react-router-dom'
import AOS from 'aos'
// import Home from './components/Body/Home/Home';

export default function App() {

  useEffect(()=>{
    AOS.init();
  },[])

  return (
    <Router>
      <ContextAppProvider>
        {/* <Home /> */}
        <Body />
      </ContextAppProvider>
    </Router>
  );
}
