import './Aboutme.css';

export const sections = [
  {
    title: 'Who We Are',
    text: 'Founded in June 2023, Bearclaw Technologies Ltd. is a forward-thinking software development and technology solutions company. We pride ourselves on our ability to provide efficient, robust, and tailor-made software solutions, empowering businesses to meet their unique goals.'
  },
  {
    title: 'Our Skills',
    text: 'Our team boasts a broad range of proficiency, specializing in front-end and back-end development, responsive design, user experience (UX), database management, API integration, and more. We bring a diverse and powerful skill set to software development, ready to meet the various needs of our clients.'
  },
  {
    title: 'Our Services',
    text: 'Our services range from web and mobile application development to technology consulting, computer building & maintenance, and the development of automated bots for financial applications. Whatever your tech needs, Bearclaw Technologies is equipped to provide innovative and tailored solutions.'
  },
  {
    title: 'Our Journey',
    text: 'The journey of Bearclaw Technologies began with Solomon Xavier Bear, a Computer Science and Economics graduate from CU Boulder. With leadership experience at various companies such as YRC Freight and a deep-rooted passion for software development, Solomon established Bearclaw Technologies to provide businesses with software solutions tailored to their unique needs and challenges.'
  },
  {
    title: 'Our Approach',
    text: 'At Bearclaw Technologies, we are committed to exceeding our clients\' expectations. Our flexible and adaptable approach enables us to meet the diverse needs of our clients, no matter the size or scope of the challenge. Whether you\'re a startup or an established corporation, we are ready to assist in shaping the future of your business with our tailored software solutions.'
  },
];
