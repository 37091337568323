import React, { useContext, useState } from 'react'
import './Contact.css'
import Card from  '../../reuseable/Card/Card'
import { contactboxes, contactinputs } from '../../Appconstant'
import Iconbox from '../../reuseable/Icon/Iconbox'
import Objectinput from '../../reuseable/Input/Objectinput'
import AppButton from '../../reuseable/Button/Button'
import emailjs from 'emailjs-com';
import { addNotification } from '../../Appfunctions'
import { ContextApp } from '../../../ContextAPI'
import Bannerprops from '../Banner/Bannerprops'
import sendMailIcon from '../../reuseable/Icon/emailDev.jpg';
import GrayBackground from '../../reuseable/Icon/grayBackground2.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';


function Contact() {
  const {notifisystem} = useContext(ContextApp)
  const [formvalues, setFormvalues] = useState({
    name: '',
    email: '',
    msg: ''
  })
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_jsaoihr', 'template_h5vq1co', e.target, 'user_B0W0FA6EBGqj9vC542Rs3')
      .then((result) => {
          const parameters = {
            msg: 'Email Sent!',
            icon: sendMailIcon,
            notifisystem
          }
          addNotification(parameters)
      }, (error) => {
          console.log(error.text);
      });
  }
  const forminputs = contactinputs?.map(input=>{
    return <Objectinput 
    text={input.text} 
    value={formvalues} 
    setValue={setFormvalues} 
    obj={input.value}
    textarea={input.textarea}
    name={input.name}
    />
  })
  const contactboxesrow = contactboxes?.map(box=>{
    return (
      <Card 
        copy={true} 
        card={box}
        iconlink={
          <a href={box.link} target={!box.notblank && '___blank'}>
            <FontAwesomeIcon icon={faChevronRight} /> 
          </a>
        }
      />
    )
  })

  return (
    <div className="contact">
          <Bannerprops 
          img={GrayBackground} 
          title='Contact Us' 
          text='Contact Bearclaw Technologies Ltd. today to build or enhance your web and mobile applications, performace optimization, and other technological solutions!' 
          />
          <div className="contactinfo">
              <div className="contactitle">
                <h2>We are looking forward to hearing from you!</h2>
                {/* <small className="graytext">
                  We're looking forward to hearing from you!
                </small> */}
              </div>
              <div className="contactboxes">
                {contactboxesrow}
              </div>
          </div>
          <div className="contactform">
          <div className="leftcontact">
          <Iconbox icon={sendMailIcon}/>
          <h2>Send Us a Message</h2>
        </div>
              <form onSubmit={(e)=>sendEmail(e)}>
                {forminputs}
                <AppButton 
            text={'Submit'} 
            icon={<FontAwesomeIcon icon={faPaperPlane} />}
          />
              </form>
          </div>
    </div>
  )
}
export default Contact
