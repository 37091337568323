// Import necessary dependencies
import React from 'react';
import './Iconbox.css';

function Iconbox({ icon }) {
  if (icon.startsWith('/')) { // If icon is an image path...
    return (
      <div className="iconbox">
        <img src={icon} alt="icon" />
      </div>
    );
  } else { // If icon is a class for an icon font...
    return (
      <div className="iconbox">
        <i className={icon}></i>
      </div>
    );
  }
}

export default Iconbox;
