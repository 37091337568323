import React from 'react'
import { gridservices } from '../../Appconstant'
import Bannerprops from '../Banner/Bannerprops'
import Gridservice from './Gridservice'
import './Services.css'
import GrayBackground from '../../reuseable/Icon/grayBackground3.jpg'

function Services() {
  const gridservicesrow = gridservices?.map(service=>{
    return (
       <Gridservice 
        service={service}
       />
    )
  })

  return (
    <div className="services">
        <Bannerprops 
        img={GrayBackground}
        title='Services' 
        text='Explore the diverse services offered by Bearclaw Technologies Ltd to help establish your online presence!'/>
        <div className="servicescontainer">
          {gridservicesrow}
        </div>
     </div>
  )
}

export default Services
