import React from 'react';
import { services } from '../../Appconstant';
import { HashLink } from 'react-router-hash-link';
import './Featuressection.css';

function Servicessection() {
  const renderServices = () => {
    return services?.map((service, index) => {
      return (
        <HashLink key={service.id} to={service.link + '#top'} className="card themeshadowcard">
          <div className="iconbox">
            <img src={service.icon} alt="" />
          </div>
          <div>
            <h4>{service.title}</h4>
            <p>{service.text}</p>
          </div>
          {service.iconlink && (
            <div className="circle-icon">
              <div className="circle"></div>
            </div>
          )}
          {service.learnmorebtn && (
            <a href={service.link + '#top'} className="text-arrow">
              Learn More
            </a>
          )}
        </HashLink>
      );
    });
  };

  return (
    <div className="featuressection">
      <div className="header">
        <h2>Services</h2>
      </div>
      <div className="cardscontainer">{renderServices()}</div>
    </div>
  );
}

export default Servicessection;
